import { LayoutPageHamburger } from '@doltech/ui/lib/layouts/pages/LayoutPageHamburger';
import styled from 'styled-components';
import * as React from 'react';
import { LandingPageHeader } from './components/LandingPageHeader';
import { Hero } from './components/sections/Hero/Hero';
import { AboutDolSuperLMS } from './components/sections/AboutDolSuperLMS/AboutDolSuperLMS';
import { UpgradeReason } from './components/sections/UpgradeReason/UpgradeReason';
import { Feature } from './components/sections/Feature/Feature';
import { CTA } from './components/sections/CTA/CTA';
import { ValueBrought } from './components/sections/ValueBrought/ValueBrought';
import { LandingPageFooter } from './components/footer/LandingPageFooter';
import { menuItems } from './components/data';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { useQuery } from 'react-query';
import { apis } from '@doltech/core/lib/api/api.config';
import { throttle } from 'lodash';

const getTrialCourseInfo = async () => {
  const res = await apis.publicCourseAppSheet.get('/trial-course/intro');
  return res.data;
};

const LmsStudentLandingPageLayout = styled.div`
  max-width: 1132px;
  margin: auto;
  padding: 80px 0;
  ${toScreen(1366)} {
    max-width: 100%;
    padding: 80px 40px;
  }
  ${toScreen(1199)} {
    max-width: 100%;
    padding: 80px 32px;
  }
  ${toScreen(959)} {
    max-width: 100%;
    padding: 80px 32px;
  }
  ${toScreen(767)} {
    max-width: 100%;
    padding: 52px 20px 80px;
  }
`;
const ToTop = styled.div``;

export const LmsStudentLandingPage = () => {
  const heroRef = React.useRef(null);
  const upgradeReasonRef = React.useRef(null);
  const featureRef = React.useRef(null);
  const valueBroughtRef = React.useRef(null);
  const aboutDolRef = React.useRef(null);
  const toTopRef = React.useRef(null);
  const [isShowToTop, setIsShowToTop] = React.useState<boolean>(false);
  const { data } = useQuery<any>('getTrialCourseInfo', () => getTrialCourseInfo(), {
    staleTime: 5_000,
  });
  const haveTrialData = data?.length > 0;

  React.useEffect(() => {
    const handleScroll = throttle(() => {
      if (featureRef?.current) {
        const { top } = featureRef.current.getBoundingClientRect();
        if (top <= 130) {
          setIsShowToTop(true);
        } else {
          setIsShowToTop(false);
        }
      }
    }, 100);
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const handleScrollToAboutDol = () => {
    if (aboutDolRef?.current) {
      aboutDolRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleScrollToView = (menuValue: string) => {
    switch (menuValue) {
      case 'gioi_thieu':
        if (heroRef?.current) {
          heroRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        break;
      case 'ly_do_phat_trien':
        if (upgradeReasonRef?.current) {
          upgradeReasonRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        break;
      case 'tinh_nang':
        if (featureRef?.current) {
          featureRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        break;
      case 'gia_tri_mang_lai':
        if (valueBroughtRef?.current) {
          valueBroughtRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        break;
      default:
        break;
    }
  };

  const handleScrollToTop = () => {
    if (toTopRef?.current) {
      toTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  return (
    <LayoutPageHamburger
      header={
        <LandingPageHeader
          menuItems={menuItems}
          handleClick={handleScrollToView}
          handleScrollToTop={handleScrollToTop}
          haveTrialData={haveTrialData}
        />
      }
    >
      <LmsStudentLandingPageLayout>
        <ToTop ref={toTopRef} />
        <Hero ref={heroRef} handleScrollToAboutDol={handleScrollToAboutDol} />
        <AboutDolSuperLMS ref={aboutDolRef} />
        <UpgradeReason ref={upgradeReasonRef} />
        <Feature ref={featureRef} />
        <CTA />
        <ValueBrought ref={valueBroughtRef} />
      </LmsStudentLandingPageLayout>
      <LandingPageFooter handleScrollToTop={handleScrollToTop} isShowToTop={isShowToTop} />
    </LayoutPageHamburger>
  );
};
