import * as React from 'react';
import styled from 'styled-components';
import { useFromScreens } from '../../hooks/useDeviceQuery.hook';
import { DolLogoWithoutText } from './index';
import { colorsV2 } from '../colors-v2';
import { flexGap } from '@doltech/utils/lib/css-style';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';

const Main = styled.div`
  display: flex;
  align-items: center;
  ${flexGap(16)};
  ${fromScreen(768)} {
    ${flexGap(20)};
  }
`;

const Main2 = styled.div`
  display: flex;
  align-items: center;
  ${flexGap(16)};
`;

const Divider = styled.div`
  width: 1px;
  background: ${colorsV2.grey20};
  height: 40px;
`;

interface DolLogoIeltsSatJuniorProps {
  hasDivider?: boolean;
  onClick?: () => void;
}

export const DolLogoIeltsSatJunior = (props: DolLogoIeltsSatJuniorProps) => {
  const { hasDivider } = props;
  const [, from768, from1024] = useFromScreens([0, 768, 1024]);

  if (from1024) {
    return (
      <Main className="dol-logo-ielts-sat-junior">
        <img
          alt="teacher-ielts-sat-junior-logo"
          width={122}
          height={40}
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/cf52a50e-d2b9-47d6-a660-c499af14f9ff.png"
          loading="eager"
        />
        {hasDivider && <Divider />}
      </Main>
    );
  }

  if (from768) {
    return (
      <Main className="dol-logo-ielts-sat-junior">
        <img
          alt="teacher-ielts-sat-junior-logo"
          width={76}
          height={38}
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/cf160096-1a33-4a89-b94d-0b18f2b622b7.png"
          loading="eager"
        />
        {hasDivider && <Divider />}
      </Main>
    );
  }

  return (
    <Main className="dol-logo-ielts-sat-junior">
      <DolLogoWithoutText />
    </Main>
  );
};

export const DolLogoIeltsSatJuniorLMSHome = (props: DolLogoIeltsSatJuniorProps) => {
  const { onClick } = props;
  return (
    <Main className="dol-logo-ielts-sat-junior-lms" onClick={() => onClick?.()}>
      <img
        alt="teacher-ielts-sat-junior-logo"
        width={208}
        height={40}
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/b90bf70b-61ae-4e0e-952a-5d397b7eda85.png"
        loading="eager"
      />
    </Main>
  );
};

export const DolLogoIeltsSatJuniorLarge = (props: DolLogoIeltsSatJuniorProps) => {
  const { hasDivider, onClick } = props;

  return (
    <Main className="dol-logo-ielts-sat-junior" onClick={() => onClick?.()}>
      <img
        alt="teacher-ielts-sat-junior-logo"
        width={122}
        height={40}
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/cf52a50e-d2b9-47d6-a660-c499af14f9ff.png"
        loading="eager"
      />
      {hasDivider && <Divider />}
    </Main>
  );
};

export const DolLogoIeltsSatJuniorMedium = (props: DolLogoIeltsSatJuniorProps) => {
  const { hasDivider } = props;

  return (
    <Main className="dol-logo-ielts-sat-junior">
      <img
        alt="teacher-ielts-sat-junior-logo"
        width={76}
        height={38}
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/cf160096-1a33-4a89-b94d-0b18f2b622b7.png"
        loading="eager"
      />
      {hasDivider && <Divider />}
    </Main>
  );
};

export const DolLogoIeltsSatJuniorAiMockTest = (props: DolLogoIeltsSatJuniorProps) => {
  const { hasDivider } = props;
  const [, from961] = useFromScreens([0, 961]);

  if (from961) {
    return (
      <Main2 className="dol-logo-ielts-sat-junior">
        <img
          alt="teacher-ielts-sat-junior-logo"
          width={122}
          height={40}
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/cf52a50e-d2b9-47d6-a660-c499af14f9ff.png"
          loading="eager"
        />
        {hasDivider && <Divider />}
      </Main2>
    );
  }

  return (
    <Main2 className="dol-logo-ielts-sat-junior">
      <DolLogoWithoutText />
    </Main2>
  );
};

export const DolLogoIeltsSatJuniorSAT = (props: DolLogoIeltsSatJuniorProps) => {
  const { hasDivider } = props;
  const [, from768] = useFromScreens([0, 768]);

  if (from768) {
    return (
      <Main2 className="dol-logo-ielts-sat-junior">
        <img
          alt="teacher-ielts-sat-junior-logo"
          width={122}
          height={40}
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/cf52a50e-d2b9-47d6-a660-c499af14f9ff.png"
          loading="eager"
        />
        {hasDivider && <Divider />}
      </Main2>
    );
  }

  return (
    <Main2 className="dol-logo-ielts-sat-junior">
      <DolLogoWithoutText />
    </Main2>
  );
};

export const DolLogoIeltsSatJuniorDictation = (props: DolLogoIeltsSatJuniorProps) => {
  const { hasDivider } = props;
  const [, from1280] = useFromScreens([0, 1280]);

  if (from1280) {
    return (
      <Main2 className="dol-logo-ielts-sat-junior">
        <img
          alt="teacher-ielts-sat-junior-logo"
          width={122}
          height={40}
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/cf52a50e-d2b9-47d6-a660-c499af14f9ff.png"
          loading="eager"
        />
        {hasDivider && <Divider />}
      </Main2>
    );
  }

  return (
    <Main2 className="dol-logo-ielts-sat-junior">
      <DolLogoWithoutText />
    </Main2>
  );
};

export const DolLogoIeltsSatJuniorDictationTuHoc = (props: DolLogoIeltsSatJuniorProps) => {
  const { hasDivider } = props;
  const [, from600, from1280] = useFromScreens([0, 600, 1280]);

  if (from1280) {
    return (
      <Main2 className="dol-logo-ielts-sat-junior">
        <img
          alt="teacher-ielts-sat-junior-logo"
          width={122}
          height={40}
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/cf52a50e-d2b9-47d6-a660-c499af14f9ff.png"
          loading="eager"
        />
        {hasDivider && <Divider />}
      </Main2>
    );
  }

  if (from600) {
    return (
      <Main className="dol-logo-ielts-sat-junior">
        <img
          alt="teacher-ielts-sat-junior-logo"
          width={76}
          height={38}
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/cf160096-1a33-4a89-b94d-0b18f2b622b7.png"
          loading="eager"
        />
        {hasDivider && <Divider />}
      </Main>
    );
  }

  return (
    <Main2 className="dol-logo-ielts-sat-junior">
      <DolLogoWithoutText />
    </Main2>
  );
};

export const DolLogoIeltsSatJuniorVocab = (props: DolLogoIeltsSatJuniorProps) => {
  const { hasDivider } = props;
  const [, from600] = useFromScreens([0, 600]);

  if (from600) {
    return (
      <Main2 className="dol-logo-ielts-sat-junior">
        <img
          alt="teacher-ielts-sat-junior-logo"
          width={122}
          height={40}
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/cf52a50e-d2b9-47d6-a660-c499af14f9ff.png"
          loading="eager"
        />
        {hasDivider && <Divider />}
      </Main2>
    );
  }

  return (
    <Main2 className="dol-logo-ielts-sat-junior">
      <DolLogoWithoutText />
      {hasDivider && <Divider />}
    </Main2>
  );
};
